import * as S from 'apps/cliniko/style'
import Button from 'components/button'
import Link from 'components/link'
import Prose from 'components/prose'
import * as E from 'modules/element'
import * as _ from 'modules/util'
import { Fragment, useLayoutEffect, useRef, useState } from 'react'

const Breadcrumbs = ({ breadcrumbs: allBreadcrumbs, className }) => {
  const [unwrapAll, setUnwrapAll] = useState(false)
  const breadcrumbs = allBreadcrumbs.slice(!unwrapAll ? -2 : 0)

  const { ref: resizeRef } = E.useResizeObserver({
    box: 'border-box',
    onResize: () => {
      setAllBreadcrumbsNavHasOverflow(
        allBreadcrumbsNav.current?.scrollWidth !== allBreadcrumbsNav.current?.clientWidth
      )
    },
  })

  const allBreadcrumbsNav = useRef()
  const hiddenMeasurementNav = _.useMergeRefs([resizeRef, allBreadcrumbsNav])

  const [allBreadcrumbsNavHasOverflow, setAllBreadcrumbsNavHasOverflow] = useState(false)
  const allBreadcrumbsHash = allBreadcrumbs.map(({ href, label }) => href || label).join('')

  useLayoutEffect(() => {
    setAllBreadcrumbsNavHasOverflow(
      allBreadcrumbsNav.current?.scrollWidth !== allBreadcrumbsNav.current?.clientWidth
    )
    setUnwrapAll(false)
  }, [allBreadcrumbsHash])

  const allBreadcrumbContents = allBreadcrumbs.map(({ href, icon, label }, index) => {
    const isLast = index + 1 === allBreadcrumbs.length
    return (
      <Fragment key={href || label}>
        {href ? (
          <Link css={linkStyles({ flex: true, truncate: false })} href={href} icon={icon}>
            {label}
          </Link>
        ) : (
          <Prose css={currentPageStyles({ flex: true, truncate: false })} size="small">
            {label}
          </Prose>
        )}
        {!isLast && <span css={dividerStyles}>/</span>}
      </Fragment>
    )
  })

  return (
    <>
      <div css={[containerStyles({ wrap: false }), hiddenStyles]} ref={hiddenMeasurementNav}>
        {allBreadcrumbContents}
      </div>
      {allBreadcrumbsNavHasOverflow ? (
        <nav
          aria-label="Breadcrumb links"
          className={className}
          css={containerStyles({ wrap: unwrapAll })}>
          {!unwrapAll && (
            <>
              <Button
                aria-label="Show all breadcrumb links"
                css={{ bottom: 1, marginBottom: S.unit(-1) }}
                link
                onClick={() => setUnwrapAll(true)}>
                …
              </Button>
              <span css={dividerStyles}>/</span>
            </>
          )}
          {breadcrumbs.map(({ href, icon, label }, index) => {
            const isLast = index + 1 === breadcrumbs.length
            return (
              <Fragment key={href || label}>
                {href ? (
                  <Link
                    css={linkStyles({ flex: !unwrapAll, truncate: isLast && !unwrapAll })}
                    href={href}
                    icon={icon}>
                    {label}
                  </Link>
                ) : (
                  <Prose
                    css={currentPageStyles({ flex: !unwrapAll, truncate: isLast && !unwrapAll })}
                    size="small">
                    {label}
                  </Prose>
                )}
                {!isLast && <span css={dividerStyles}>/</span>}
              </Fragment>
            )
          })}
        </nav>
      ) : (
        <nav
          aria-label="Breadcrumb links"
          className={className}
          css={containerStyles({ wrap: false })}>
          {allBreadcrumbContents}
        </nav>
      )}
    </>
  )
}

const hiddenStyles = {
  height: 0,
  marginBottom: 0,
  visibility: 'hidden',
}

const containerStyles = ({ wrap }) =>
  wrap
    ? S.flexWrap({ columnGap: S.unit(0.5) })
    : { display: 'flex', alignItems: 'start', gap: S.unit(0.5) }

const linkStyles = ({ flex, truncate }) => [
  truncate && S.textTruncate,
  !truncate && flex && { flex: '0 0 auto' },
  {
    zIndex: 1,
    bottom: S.unit(-0.5),
    fontSize: S.unit(1.5),
    lineHeight: S.unit(2),

    '> svg': {
      bottom: S.rem(-1),
      width: S.unit(1.5),
    },
  },
]

const currentPageStyles = ({ flex, truncate }) => [
  truncate && S.textTruncate,
  !truncate && flex && { flex: '0 0 auto' },
  { color: S.colors.grey(10) },
]

const dividerStyles = {
  color: S.colors.pink(7),
  fontSize: S.unit(1.5),
  position: 'relative',
  bottom: S.unit(-0.75),
  zIndex: 0,
}

export default Breadcrumbs
